import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { MediaQuery } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'advertisers--block': {
    borderBottom: `1px solid ${variables.$dividerColor}`,
  },
  'advertisers-list--block': {
    overflowX: 'hidden',
    '@media (hover: hover)': {
      paddingRight: '5px',
      '&::-webkit-scrollbar': {
        width: '3px',
        backgroundColor: '#003f8f',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: variables.$helperTextColor,
      },
      scrollbarWidth: 'thin',
      scrollbarColor: '#C7C7C7 #003f8f',
    },
  },
  'show-all-btn': {
    color: '#C3CBD5',
    fontSize: '13px',
    cursor: 'pointer',
  },
  'additional-info--block': {
    paddingTop: '16px',
    '& .website': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& ul': {
      padding: 0,
      listStyleType: 'none',
      '& li': {
        display: 'grid',
        gridTemplateColumns: '26px 1fr',
        alignItems: 'center',
        fontSize: '13px',
        marginBottom: '10px',
        color: '#fff',
        '& a': {
          color: 'inherit',
          '&:hover': {
            textDecoration: 'underline !important',
          },
        },
        '& p': {
          margin: 0,
        },
      },
    },
  },
  'actions--block': {
    ...MediaQuery.down({
      position: 'fixed',
      left: '0',
      width: '100%',
      background: '#003f8f',
      padding: '10px',
      bottom: '0',
      zIndex: '999',
    }).sm,
    '& a': {
      fontSize: '13px',
      margin: '0px',
      width: '100%',
      display: 'flex',
      color: '#fff',
      border: 'none',
      background: 'linear-gradient(180deg, #7AD100 0%, #5C9E00 100%)',
      outline: 'none',
      padding: '11.5px 16px',
      transition: '.1s',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      borderRadius: '2px',
      justifyContent: 'center',
      '& span': {
        marginRight: '7px',
      },
      '&:hover': {
        background: 'linear-gradient(180deg, #89e802 0%, #68af04 100%)',
      },
    },
  },
});
