import clsx from 'clsx';

export const PrevArrow = ({ onClick, needToHide }) => (
  <span
    onClick={onClick}
    className={clsx('icon-Chevron---Left', 'arrow', { hide: needToHide })}
  />
);

export const NextArrow = ({ onClick, needToHide }) => (
  <span
    onClick={onClick}
    className={clsx('icon-Chevron---Right', 'arrow', { hide: needToHide })}
  />
);
