import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'next-intl';

import Advertiser from 'Components/Ads/SharedComponents/Advertiser';
import { Button } from 'Components/Shared/UI';

import getProfileWithoutRequiredField from 'utils/getProfileWithoutRequiredField';

import { authSelector } from 'store/reselect';
import { setLoginByDialog } from 'store/actions';
import { deviceParams } from 'store/reselect';

import useStyles from './styles';

const Advertisers = ({
  profileData,
  adPromotions,
  phoneNumbers,
  bodyPaddingBottom,
  disableMessaging,
  additionalInformation,
}) => {
  const t = useTranslations();
  const { deviceType } = useSelector(deviceParams());
  const authState = useSelector(authSelector());
  const { user, accountProfiles } = authState;
  const dispatch = useDispatch();
  const styles = useStyles();
  const [showNumber, setShowNumber] = useState(false);

  const sendMessage = () => {
    const profileWithoutRequiredField = getProfileWithoutRequiredField(
      authState.accountProfiles,
    );

    let viewKey;

    if (user.id && user.role !== 'company' && profileWithoutRequiredField) {
      viewKey = 'edit-profile';
    } else if (user.id && !accountProfiles.length) {
      viewKey = 'create-profile';
    } else if (user.id) {
      viewKey = 'send-message';
    } else {
      viewKey = 'login';
    }

    dispatch(setLoginByDialog(viewKey));
  };

  useEffect(() => {
    // For the Actions buttons the body should have padding on mobile
    if (typeof window !== 'undefined' && deviceType === 'mobile') {
      document.body.style.paddingBottom = bodyPaddingBottom ? '110px' : 'unset';
    }
  }, [bodyPaddingBottom, deviceType]);

  useEffect(
    () => () => {
      document.body.style.paddingBottom = 'unset';
    },
    [],
  );

  return (
    <div className="content-with-shadow mb-3 save-bg-on--mobile">
      <div className={styles['advertisers--block']}>
        <div className={styles['advertisers-list--block']}>
          <Advertiser profileData={profileData} />
        </div>
        {/* {!noMore ? (
          <p className={styles['show-all-btn']} onClick={toggleAdProfiles}>
            {toggleCollapse
              ? 'Show less'
              : t('ads_detailed_show_all_button_text')}
          </p>
        ) : null} */}
      </div>
      <div className={styles['additional-info--block']}>
        <ul>
          <li>
            <span className="icon-Locatie"></span>
            <p>{`${
              additionalInformation?.location?.settlement || t('no_data_city')
            }`}</p>
          </li>
          {!!adPromotions?.websites?.length && (
            <li>
              <span className="icon-Globe"></span>
              <p className="website">
                {adPromotions?.websites.map((e, index) => (
                  // eslint-disable-next-line
                  <a
                    href={
                      e.value?.includes('http') ? e.value : `https://${e.value}`
                    }
                    key={e.id}
                    target="_blank"
                  >
                    {e.value}
                    {index + 1 < adPromotions?.websites.length ? ', ' : ''}
                  </a>
                ))}
              </p>
            </li>
          )}
        </ul>
        <div className={styles['actions--block']}>
          {showNumber ? (
            phoneNumbers.map(phone => (
              <a
                href={`tel:+${phone?.country_code + phone?.number}`}
                onClick={() => setShowNumber(!showNumber)}
                key={phone.international_number}
              >
                <span className="icon-Phone"></span>
                +({phone.country_code}) {phone.number}{' '}
                {/* todo need some clarify of phone number */}
              </a>
            ))
          ) : phoneNumbers?.length ? (
            <Button
              icon={{ direction: 'left', className: 'icon-Phone' }}
              text={t('ads_detailed_show_number_button')}
              fullWidth
              typeButton="cta"
              onClick={() => setShowNumber(!showNumber)}
            />
          ) : (
            ''
          )}
          {!disableMessaging ? (
            <Button
              margin={[10, 0, 0]}
              icon={{ direction: 'left', className: 'icon-Message-_-Mail' }}
              text={t('ads_detailed_send_message_button')}
              fullWidth
              typeButton="primary"
              onClick={sendMessage}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Advertisers;
