import { getChangesData } from './getApproveData';

const getProfileWithoutRequiredField = profiles =>
  profiles.find(
    profile =>
      !getChangesData(profile, 'languages')?.length ||
      !getChangesData(profile, 'descent'),
  );

export default getProfileWithoutRequiredField;
