import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from 'next-intl';

import sortByTranslation from 'utils/sortByTranslation';

import useStyles from './styles';

const Possibilities = ({ data }) => {
  const t = useTranslations();
  const styles = useStyles();

  return (
    <div className="content-with-shadow mb-20 save-bg-on--mobile">
      <div className={styles['possibilities--block']}>
        <h3 className="title-content">{t('possibilities_title')}</h3>
        <ul className="list-of-checked">
          {data?.length
            ? sortByTranslation({ data, key: 'name' }, t).map(possibility => (
                <li key={possibility.id}>{possibility.t_name}</li>
              ))
            : 'no_data_services'}
        </ul>
      </div>
    </div>
  );
};
Possibilities.propTypes = {
  data: PropTypes.array.isRequired,
};
export default memo(Possibilities);
