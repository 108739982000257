/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, memo, useState, useRef } from 'react';
import Slider from 'react-slick';
import { AnimatePresence } from 'framer-motion';
import useMemoSelector from 'hooks/useMemoSelector';

import Image from 'Components/Shared/UI/Image';

import { deviceParams } from 'store/reselect';

import LightBox from './LightBox';
import { PrevArrow, NextArrow } from './Arrows';
import useStyles from './styles';

const GallerySlider = ({ images, showThumbs = true }) => {
  const sliderRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);
  const [groupNode, setGroupNode] = useState(null);
  const [isOpenBox, setIsOpenBox] = useState(false);
  const [clientWidth, setClientWidth] = useState(0);
  const { deviceType } = useMemoSelector(deviceParams());
  const [scrollPosition, setScrollPosition] = useState(0);
  const [lightBoxSlideIndex, setLightBoxSlideIndex] = useState(0);
  const { sliderContainer, imageContainer, counterBlock } = useStyles({
    imagesLength: images.length,
  });

  const isDesktop = deviceType === 'desktop';

  useEffect(() => {
    sliderRef.current?.slickGoTo(lightBoxSlideIndex);
  }, [sliderRef, lightBoxSlideIndex]);

  useEffect(() => {
    if (groupNode && isDesktop && showThumbs) {
      groupNode.scrollTo({
        top: 0,
        behavior: 'smooth',
        left: scrollPosition,
      });
    }
  }, [groupNode, scrollPosition, isDesktop, showThumbs]);

  return (
    <div
      ref={node => {
        if (node) {
          const thumbs = node.querySelector('.slick-thumb');
          if (thumbs) {
            setGroupNode(thumbs);
            setClientWidth(thumbs.clientWidth);
          }
        }
      }}
      className={sliderContainer}
    >
      <Slider
        ref={sliderRef}
        infinite={false}
        slidesToShow={1}
        slidesToScroll={1}
        dotsClass="slick-dots slick-thumb"
        beforeChange={(_, next) => setSlideIndex(next)}
        prevArrow={<PrevArrow needToHide={slideIndex === 0} />}
        nextArrow={<NextArrow needToHide={images.length - 1 === slideIndex} />}
        {...(isDesktop &&
          showThumbs && {
            dots: true,
            customPaging: i => (
              <div>
                <img
                  src={images[i]}
                  style={{ width: '60px', height: '60px' }}
                />
              </div>
            ),
          })}
      >
        {images.map(image => (
          <div
            key={image}
            className={imageContainer}
            onClick={() => setIsOpenBox(true)}
          >
            <Image src={image} />
          </div>
        ))}
      </Slider>
      <div className={counterBlock}>
        <p>{`${slideIndex + 1} / ${images.length}`}</p>
      </div>
      {deviceType === 'desktop' && (
        <div className="thumbs-buttons-container">
          <div className="thumbs-buttons--block">
            <div>
              {scrollPosition > 0 && (
                <span
                  className="icon-Chevron---Left"
                  onClick={() =>
                    setScrollPosition(
                      groupNode?.scrollLeft - groupNode?.scrollWidth / 10,
                    )
                  }
                />
              )}
            </div>
            <div>
              {scrollPosition + clientWidth < groupNode?.scrollWidth && (
                <span
                  className="icon-Chevron---Right"
                  onClick={() =>
                    setScrollPosition(
                      groupNode?.scrollLeft + groupNode?.scrollWidth / 10,
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
      <AnimatePresence>
        {isOpenBox && (
          <LightBox
            images={images}
            activeImageIndex={slideIndex}
            lightBoxSlideIndex={lightBoxSlideIndex}
            closeLightBox={() => setIsOpenBox(false)}
            setLightBoxSlideIndex={setLightBoxSlideIndex}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default memo(GallerySlider);
