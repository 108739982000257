import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';

export default makeStyles({
  'advertiser--block': {
    marginBottom: '8px',
    paddingBottom: '8px',
    '&.advertiser--block__border': {
      borderBottom: `1px solid ${variables.$dividerColor}`,
    },
  },
  'advertiser-header--block': {
    display: 'grid',
    gridTemplateColumns: '40px 1fr',
    gridTemplateRows: 'auto',
  },
  'advertiser-header--rgt': {
    display: 'grid',
    gridTemplateColumns: '1fr 30px',
    alignItems: 'center',
    cursor: 'pointer',
    '& .icon-Accordion-Open': {
      textAlign: 'center',
      fontSize: '10px',
      color: variables.$iconColor,
    },
  },
  'advertiser-header--info': {
    '& h5': {
      marginBottom: '4px',
      fontSize: '13px',
      fontWeight: 'bold',
      color: '#fff',
    },
    '& p': {
      margin: '0',
      fontSize: '11px',
      color: '#fff',
    },
  },
  'advertiser-body--block': {
    paddingTop: '16px',
    '& .profile-details': {},
    '& .flex': {
      display: 'flex',
    },
    '& .ml10': {
      marginLeft: '10px',
    },
    '& .ml4': {
      marginLeft: '4px',
    },
    '& .profile-working-ours': {
      '.& .opening-hours': {
        textTransform: 'capitalize',
      },
      '& .weekday': {
        width: '60px',
      },
      '& .from-to': {
        whiteSpace: 'nowrap',
      },
    },
    '& ul': {
      margin: '0',
      paddingLeft: '40px',
      '& li': {
        display: 'flex',
        color: '#fff',
        fontSize: '11px',
        marginBottom: '5px',
        '& > span': {
          '&:first-child': {
            minWidth: '80px',
            color: variables.$helperTextColor,
          },
        },
        '&:last-child': {
          borderBottom: '0',
        },
      },
    },
  },
});
