import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'availability--block': {
    '& ul': {
      margin: '0',
      paddingLeft: '0px',
      '& li': {
        display: 'flex',
        color: '#fff',
        fontSize: '13px',
        marginBottom: '10px',
        '& span': {
          '&:first-child': {
            minWidth: '90px',
          },
        },
      },
    },
  },
});
