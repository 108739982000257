import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';
import { SubTitleInsideMainContent } from 'static/styles/jss/abstracts/mixins';

export default makeStyles({
  'description-content': {
    height: props => props.maxHeight,
    display: 'grid',
    gridTemplate: 'auto 1fr auto / 1fr',
    padding: '20px 0',
    '& a': {
      fontSize: '13px',
      cursor: 'pointer',
      marginTop: '7px',
      color: [variables.$helperTextColor, '!important'],
      '& span': {
        fontSize: '11px',
        position: 'relative',
        top: '1px',
        marginLeft: '3px',
        display: 'inline-block',
      },
    },
  },
  'overflow-content--block': {
    overflowX: 'hidden',
    overflow: props => props.overflow,
    height: 'auto',
    marginRight: '5px',
    ...SubTitleInsideMainContent({
      marginBottom: '0',
    }),
    '@media (hover: hover)': {
      '&::-webkit-scrollbar': {
        width: '5px',
        backgroundColor: variables.$bodyColor,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: variables.$helperTextColor,
      },
      scrollbarWidth: 'thin',
      scrollbarColor: '#C7C7C7 #07224D',
    },
    '& p': {
      margin: '0',
      wordBreak: 'break-word',
    },
  },
});
