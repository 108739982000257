import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from 'next-intl';
import { v4 } from 'uuid';

import useStyles from './styles';

const Availability = ({ data }) => {
  const t = useTranslations();
  const styles = useStyles();

  return (
    <div className="content-with-shadow mb-0 save-bg-on--mobile">
      <div className={styles['availability--block']}>
        <h3 className="title-content">{t('availability_title')}</h3>
        <ul>
          {data?.map(day => (
            <li key={v4()}>
              <span>{`${t(day.week_day)}: ${day.from} - ${day.to}`}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
Availability.propTypes = {
  data: PropTypes.array.isRequired,
};
export default memo(Availability);
