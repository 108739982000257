import { memo } from 'react';
import { format } from 'date-fns';
import { useTranslations } from 'next-intl';
import { BiCalendar } from 'react-icons/bi';
import { CiLocationOn } from 'react-icons/ci';
import { AiOutlineClockCircle, AiOutlineTag } from 'react-icons/ai';
import nl from 'date-fns/locale/nl';
import useMemoSelector from 'hooks/useMemoSelector';

import { convertCapitalize } from 'utils/handlers';

import { getLanguage } from 'store/reselect';

const textStyles = { color: 'rgb(255, 255, 255)' };
const iconStyles = { color: 'rgb(255, 255, 255)', margin: '10 10' };

const EventDetails = ({
  location: { settlement },
  start_date: startDate,
  event_type: eventType,
}) => {
  const t = useTranslations();
  const lang = useMemoSelector(getLanguage);

  const locale = lang === 'nl' ? { locale: nl } : {};

  return (
    <div className="content-with-shadow">
      <div>
        <BiCalendar size={25} style={iconStyles} />
        <span style={{ ...textStyles, textTransform: 'capitalize' }}>{`${t(
          'event_details_startitng_date',
        )}: ${format(new Date(startDate), 'EEEE dd MMMM', locale)}`}</span>
      </div>
      <div>
        <AiOutlineClockCircle size={25} style={iconStyles} />
        <span style={textStyles}>{`${t(
          'event_details_startitng_time',
        )}: ${format(new Date(startDate), 'HH:mm')}`}</span>
      </div>
      <div>
        <AiOutlineTag size={25} style={iconStyles} />
        <span style={textStyles}>
          {`${t('event_details_event_type')}: ${convertCapitalize(
            eventType || 'No Data',
          )}`}
        </span>
      </div>
      <div>
        <CiLocationOn size={25} style={iconStyles} />
        <span style={textStyles}>{`${t(
          'location_placeholder',
        )}: ${settlement}`}</span>
      </div>
    </div>
  );
};

export default memo(EventDetails);
