import { memo } from 'react';
import { useTranslations } from 'next-intl';

import { convertCapitalize } from 'utils/handlers';

import useStyles from './styles';

const Prices = ({ data, isEvent }) => {
  const t = useTranslations();
  const styles = useStyles();

  return (
    <div className="content-with-shadow mb-20 save-bg-on--mobile">
      <div className={styles['prices--block']}>
        <h3 className="title-content">{t('prices_title')}</h3>
        <ul>
          {data.map(({ title, time, type, price, currency }) => (
            <li key={title}>
              <span>{`${
                !isEvent ? `${time} ${t(type)}` : convertCapitalize(t(type))
              }: ${currency === 'eur' ? '€' : currency} ${price}`}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default memo(Prices);
