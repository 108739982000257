import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  headerRect as headerRectGetter,
  deviceParams as deviceParamsGetters,
} from 'store/reselect';

import useStyles from './styles';

const Description = ({ title, mobileShowAll, description }) => {
  const deviceParams = useSelector(deviceParamsGetters());
  const headerRect = useSelector(headerRectGetter()) || {};
  const { deviceSize, deviceType } = deviceParams;

  const [showMore, setShowMore] = useState(false);
  const [truncated, setTruncated] = useState(false);

  const styles = useStyles({
    maxHeight: mobileShowAll.indexOf(deviceSize) > -1 && 'auto',
    overflow:
      showMore && mobileShowAll.indexOf(deviceSize) === -1 ? 'auto' : 'hidden',
  });

  const descRef = useRef();

  const toggleShowMore = () => {
    if (mobileShowAll.indexOf(deviceSize) > -1 && showMore) {
      window.scrollTo({
        top:
          descRef.current.getBoundingClientRect().top +
          (deviceType !== 'mobile' ? 0 : headerRect.height),
        left: 0,
        behavior: 'smooth',
      });
    }
    setShowMore(!showMore);
  };

  useEffect(() => {
    if (descRef.current && typeof window !== 'undefined') {
      if (!showMore) descRef.current.scrollTop = 0;
      if (
        !truncated &&
        descRef.current.getBoundingClientRect().height <
          descRef.current.firstChild.offsetHeight
      ) {
        setTruncated(true);
      }
    }
  }, [descRef, showMore, truncated, deviceSize, mobileShowAll]);

  return (
    <div
      className={`content-with-shadow mb-0 save-bg-on--mobile ${styles['description-content']}`}
    >
      <h3 className="title-content">{title}</h3>
      <div className={styles['overflow-content--block']} ref={descRef}>
        <p
          dangerouslySetInnerHTML={{
            __html:
              description.replace(/\n/g, '<br />') || 'no_data_description',
          }}
        />
      </div>
    </div>
  );
};

Description.defaultProps = {
  mobileShowAll: [],
};
Description.propTypes = {
  title: PropTypes.string,
  mobileShowAll: PropTypes.array,
  maxHeight: PropTypes.number,
};
export default Description;
