import { makeStyles } from '@material-ui/core/styles';
import variables from 'static/styles/jss/abstracts/variables';

const arrowStyles = {
  display: 'block',
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)',
  fontSize: '14px',
  color: '#fff',
  width: '40px',
  height: '40px',
  lineHeight: '40px',
  textAlign: 'center',
  opacity: '.8',
  cursor: 'pointer',
  zIndex: 10,
  '&:hover': {
    opacity: '1',
  },
  '&.icon-Chevron---Right': {
    right: '10px',
    '&::before': {
      marginRight: '-2px',
    },
  },
  '&.icon-Chevron---Left': {
    left: '10px',
    '&::before': {
      marginLeft: '-2px',
    },
  },
};

const imageWidth = 60;
const imageMargin = 10;
const imageBlockWidth = 420;

export const useLightBoxStyles = makeStyles({
  lightBoxContainer: {
    '& .slick-track': {
      display: 'flex',
      alignItems: 'center',
    },
    '& div': {
      outline: 'none',
    },
    '& .hide': {
      display: 'none !important',
    },
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    zIndex: '999',
    '& .arrow': {
      ...arrowStyles,
    },
  },
  lightBoxTopPanel: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    padding: '30px',
    position: 'relative',
    zIndex: 33,
    '& span': {
      marginRight: '20px',
      cursor: 'pointer',
    },
    '& p': {
      fontSize: '13px',
      margin: '0',
    },
  },
  lightBoxImagesContainer: {
    marginTop: '-80px',
    display: 'grid',
    placeItems: 'center',
  },
  imageContainer: {
    textAlign: 'center',
    padding: '20px',
    width: '100%',
    display: 'flex !important',
    height: 'calc(100vh - 80px)',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      display: 'inline-block',
      maxHeight: '600px',
      width: ({ isMobile }) => isMobile && '100%',
      cursor: 'pointer',
      objectFit: 'contain',
    },
  },
});

export default makeStyles({
  sliderContainer: {
    '& div': {
      outline: 'none',
    },
    '& .hide': {
      display: 'none !important',
    },
    '& .arrow': {
      ...arrowStyles,
      backgroundColor: 'rgba(0, 0, 0, .8)',
      fontSize: '20px',
      borderRadius: ' 50%',
    },
    '& .thumbs-buttons-container': {
      userSelect: 'none',
      width: '410px',
      margin: 'auto',
      position: 'relative',
      bottom: '30px',
      pointerEvents: 'none',
      '& .thumbs-buttons--block': {
        display: 'flex',
        justifyContent: 'space-between',
        '& .icon-Chevron---Right': {
          marginRight: '5px',
        },
        '& .icon-Chevron---Left': {
          marginLeft: '5px',
        },
        '& span': {
          color: '#fff',
          width: '20px',
          cursor: 'pointer',
          height: '20px',
          display: 'flex',
          opacity: 0.8,
          fontSize: '8px',
          textAlign: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, .8)',
          pointerEvents: 'all',
        },
      },
    },
    backgroundColor: variables.$baseColorDark_90,
    padding: '20px 0',
    position: 'relative',
    '& .slick-arrow.slick-next': {
      right: 10,
    },
    '& .slick-arrow.slick-prev': {
      left: 10,
    },
    '& .slick-dots': {
      padding: 0,
      display: 'flex',
      position: 'relative',
      top: 10,
      '& li': {
        width: '60px',
        height: '60px',
        margin: '5px',
        '& img': {
          objectFit: 'cover',
          cursor: 'pointer',
        },
      },
    },
    '& .slick-thumb': {
      width: ({ imagesLength }) =>
        imagesLength < 6
          ? `${imagesLength * (imageWidth + imageMargin)}px`
          : `${imageBlockWidth}px`,
      margin: 'auto',
      display: 'flex !important',
      position: 'relative',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      scrollbarWidth: 'none',
    },
  },
  counterBlock: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    borderRadius: '5px',
    padding: '7px 11px',
    backgroundColor: 'rgba(0, 0, 0, .8)',
    '& p': {
      fontSize: '13px',
      color: '#fff',
      margin: '0',
    },
  },
  imageContainer: {
    textAlign: 'center',
    '& > img': {
      display: 'inline-block',
      height: '285px',
      width: '100%',
      cursor: 'pointer',
      objectFit: 'contain',
    },
  },
});
