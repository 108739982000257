import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  'possibilities--block': {
    '& .list-of-checked': {
      margin: 0,
      '& li': {
        color: '#fff',
        paddingLeft: '25px',
        marginBottom: '7px',
      },
    },
  },
});
