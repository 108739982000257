import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { useTranslations } from 'next-intl';

import { Avatar } from 'Components/Shared/UI';

import calculateAge from 'utils/calculateAge';
import { convertCapitalize } from 'utils/handlers';

import useStyles from './styles';

const CompanyProfileInfo = ({
  t,
  profile: { company_type, opening_hours },
}) => (
  <ul>
    <li className="profile-details">
      <span>{t('profile_details_company_type')}</span>
      <span className="ml10">
        {company_type
          ? t(`company_type_${company_type.replace(' ', '_')}`)
          : '-'}
      </span>
    </li>
    <li className="profile-working-ours flex">
      <span>{t('profile_details_opening_hours')}</span>
      <div className="opening-hours">
        {opening_hours
          ? opening_hours.map(({ weekday, from, to }) => (
              <div className="flex ml10">
                <span className="weekday">{t(weekday)}</span>
                <span className="ml4 from-to">
                  {from} - {to}
                </span>
              </div>
            ))
          : '-'}
      </div>
    </li>
  </ul>
);

const Advertiser = ({ profileData }) => {
  const t = useTranslations();
  const styles = useStyles();
  const [collapsedProfileId, setCollapsedProfileId] = useState(
    profileData[0]?._id,
  );

  const isCompany = profileData?.some(({ _meta }) => _meta?.type === 'company');

  return (
    !!profileData?.length &&
    profileData?.map(profile => {
      const {
        height,
        build,
        avatar,
        gender,
        descent,
        _id: id,
        cup_size,
        showname,
        languages,
        hair_color,
        withDivider,
        penis_length,
        date_of_birth,
      } = profile;
      const age = calculateAge(date_of_birth);

      return (
        <div
          className={`${styles['advertiser--block']} ${
            withDivider ? 'advertiser--block__border' : ''
          }`}
          key={id}
        >
          <div
            className={styles['advertiser-header--block']}
            onClick={() =>
              setCollapsedProfileId(collapsedProfileId === id ? null : id)
            }
          >
            <Avatar
              avatarSrc={avatar || ''}
              height={30}
              width={30}
              name={showname}
            />
            <div className={styles['advertiser-header--rgt']}>
              <div className={styles['advertiser-header--info']}>
                <h5>{showname}</h5>
                {gender && age && (
                  <p>
                    {t(`gender_${gender?.toLowerCase()}`)}, {age}{' '}
                    {t('profile_years')}
                  </p>
                )}
              </div>
              <span className="icon-Accordion-Open"></span>
            </div>
          </div>
          <Collapse isOpen={collapsedProfileId === id}>
            <div className={styles['advertiser-body--block']}>
              {isCompany ? (
                <CompanyProfileInfo t={t} profile={profile} />
              ) : (
                <ul>
                  <li>
                    <span>{t('profile_details_height')}:</span>
                    <span>{height ? `${height} cm` : '-'}</span>
                  </li>
                  <li>
                    <span>{t('profile_details_hair_color')}</span>
                    <span>
                      {hair_color
                        ? t(
                            `hair_color_${hair_color
                              ?.toLowerCase()
                              .replace(/ /g, '_')}`,
                          )
                        : '-'}
                    </span>
                  </li>
                  <li>
                    <span>{t('profile_details_build')}</span>
                    <span>
                      {build ? t(`build_${build?.toLowerCase()}`) : '-'}
                    </span>
                  </li>
                  {gender === 'shemale' ? (
                    <>
                      <li>
                        <span>{t('profile_details_cup')}</span>
                        <span>
                          {convertCapitalize(cup_size, 'uppercase') || '-'}
                        </span>
                      </li>
                      <li>
                        <span>{t('profile_details_penis')}</span>
                        <span>{penis_length || '-'}</span>
                      </li>
                    </>
                  ) : gender === 'female' ? (
                    <li>
                      <span>{t('profile_details_cup')}</span>
                      <span>
                        {convertCapitalize(cup_size, 'uppercase') || '-'}
                      </span>
                    </li>
                  ) : (
                    <li>
                      <span>{t('profile_details_penis')}</span>
                      <span>{penis_length || '-'}</span>
                    </li>
                  )}
                  <li>
                    <span>{t('profile_details_descent')}</span>
                    <span>
                      {descent
                        ? t(
                            `nationality_${descent
                              ?.toLowerCase()
                              .replace(/ /g, '_')}`,
                          )
                        : '-'}
                    </span>
                  </li>
                  {!!languages?.length && (
                    <li>
                      <span>{t('profile_details_languages')}</span>
                      <span>
                        {languages?.length
                          ? languages
                              ?.map(({ value }) =>
                                t(`languages_${value?.toLowerCase()}`),
                              )
                              ?.join(', ')
                          : '-'}
                      </span>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </Collapse>
        </div>
      );
    })
  );
};

export default React.memo(Advertiser);
