/* eslint-disable import/no-extraneous-dependencies */
import { memo, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import Portal from 'hoc/Portal';
import Slider from 'react-slick';
import useMemoSelector from 'hooks/useMemoSelector';
import useLockBodyScroll from 'hooks/useLockBodyScroll';

import { deviceParams } from 'store/reselect';

import { PrevArrow, NextArrow } from './Arrows';
import { useLightBoxStyles } from './styles';

const existingClassList = [
  'slider-image',
  'icon-Chevron---Left',
  'icon-Chevron---Right',
];

const LightBox = ({
  images,
  closeLightBox,
  activeImageIndex,
  lightBoxSlideIndex,
  setLightBoxSlideIndex,
}) => {
  const sliderRef = useRef();
  const { deviceType } = useMemoSelector(deviceParams());
  const styles = useLightBoxStyles({ isMobile: deviceType === 'mobile' });

  useLockBodyScroll();

  const handleOutsideClick = e => {
    if (
      e.target.classList[0] &&
      !existingClassList.includes(e.target.classList[0])
    ) {
      closeLightBox();
    }
  };

  useEffect(() => {
    sliderRef.current?.slickGoTo(activeImageIndex);
  }, [sliderRef, activeImageIndex]);

  return (
    <Portal>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.lightBoxContainer}
        onClick={handleOutsideClick}
      >
        <div className={styles.lightBoxTopPanel}>
          <span className="icon-Close" onClick={closeLightBox}></span>
          <p>
            {lightBoxSlideIndex + 1} / {images.length}
          </p>
        </div>

        <Slider
          ref={sliderRef}
          slidesToShow={1}
          infinite={false}
          slidesToScroll={1}
          dotsClass="slick-dots slick-thumb"
          beforeChange={(_, next) => setLightBoxSlideIndex(next)}
          prevArrow={<PrevArrow needToHide={lightBoxSlideIndex === 0} />}
          nextArrow={
            <NextArrow needToHide={images.length - 1 === lightBoxSlideIndex} />
          }
        >
          {images.map(image => (
            <div key={image} className={styles.imageContainer}>
              <img src={image} />
            </div>
          ))}
        </Slider>
      </motion.div>
    </Portal>
  );
};

export default memo(LightBox);
